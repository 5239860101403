import Banner1 from "../assets/greencare-mx.png";
//import Banner1 from "../assets/Banner-Greencare-Cyberday1.jpg";
//import Banner2 from "../assets/Banner-Greencare-Cyberday2.jpg";

const descript = [
  {
    image: Banner1
  }//,
  //{
  //  image: Banner2
  //}
]

export default descript