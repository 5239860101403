import React from 'react';
import ReactDOM from 'react-dom';
import './main.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import 'bulma/bulma.sass';

// Facebook Pixel
ReactPixel.init(process.env.REACT_APP_PIXEL_ID);
ReactPixel.pageView();
ReactPixel.fbq('track', 'PageView');

// Google analytics
ReactGA.initialize(process.env.REACT_APP_GA_ID);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
serviceWorker.unregister();
