import React from "react";
// import { Link } from "react-router-dom";
import Logo from "../assets/logo-greencare.png";
import history from "../helpers/history"
import CyberMonday from "../assets/logocybermonday-huinchas.png";
import Carousel from "./Carousel.jsx"



import { ReactComponent as InstaIcon } from "../assets/svg/instagram.svg";
import { ReactComponent as FaceIcon } from "../assets/svg/facebook.svg";

const Header = () => {
    const handleClick = (e) =>{
        history.push("/")
    }
    return (
        <header className="header">
            <div className="green-header-bar">
            {/* <div className="green-header-bar"><a href="/"><img src={CyberMonday} className="header-top"/></a><b className="header-top-text"> HASTA 70% OFF</b> */}
            {/* <p id="denda-week-timer" className="header-timer">
                <span id="denda-week-timer1"></span>
                <span id="denda-week-timer2"></span>
                <span id="denda-week-timer3"></span>
                <span id="denda-week-timer4"></span>
            </p> */}
            </div>
            <div className="columns">
                <div className="column is-12 logo-container">
                    <div className="logo-centered logo-shop">
                    <a href="/"><img src={Logo} alt=""/></a>
                    </div>
                    <div className="banner banner-shop">
                        <div className="social-icon instagram is-pulled-right">
                            <a href="https://www.instagram.com/greencare_mexico" target="_blank" rel="noopener noreferrer">
                                <InstaIcon />
                            </a>
                        </div>
                        <div className="social-icon facebook is-pulled-right">
                            <a href="https://www.facebook.com/GreenCare-M%C3%A9xico-108662817973611" target="_blank" rel="noopener noreferrer">
                                <FaceIcon />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="columns">
                <div className="column is-12" style={{ paddingTop: "0"}}>
                    <div className="is-centered home-text">
                        <p>
                          Cuidamos lo que más quieres
                        </p>
                    </div>
                 <div className="green-header-bar-bottom"></div>
                </div>
            </div>
            <div className="columns">
                <div className="column is-12" style={{ paddingTop: "0"}}>
                    <div className="is-centered home-text">
                        <Carousel/>
                    </div>
                 <div className="green-header-bar-bottom"></div>
                </div>
            </div>
        </header>
    );
};
export default Header;
